import BaseInstance from "../base.instance";
import {
  PRESENSI_SCHEDULE_UNIT_LIST,
  SCHEDULE_UNIT_DETAIL,
  PRESENSI_SCHEDULE_UNIT_SAVE,
  SCHEDULE_UNIT_DELETE,
  PRESENSI_SCHEDULE_UNIT_ACTIVE,
  SCHEDULE_UNIT_LIST_BY_UNIT,
  PRESENSI_SCHEDULE_UNIT_SETDEFAULT,
  PRESENSI_SCHEDULE_UNIT_SETDEFAULT_WFH
} from "../constants";

const ScheduleUnitService = {
  getList(data) {
    return BaseInstance.post(PRESENSI_SCHEDULE_UNIT_LIST, data);
  },
  getListActive(params) {
    return BaseInstance.query(PRESENSI_SCHEDULE_UNIT_ACTIVE, { params });
  },
  getListByUnit(data) {
    return BaseInstance.post(SCHEDULE_UNIT_LIST_BY_UNIT, data);
  },
  getDetail(id) {
    return BaseInstance.fetch(SCHEDULE_UNIT_DETAIL, id);
  },
  save(data) {
    return BaseInstance.post(PRESENSI_SCHEDULE_UNIT_SAVE, data);
  },
  delete(id) {
    return BaseInstance.deleteSlug(SCHEDULE_UNIT_DELETE, id);
  },
  setDefault(data) {
    return BaseInstance.post(PRESENSI_SCHEDULE_UNIT_SETDEFAULT, data);
  },
  setDefaultWFH(data) {
    return BaseInstance.post(PRESENSI_SCHEDULE_UNIT_SETDEFAULT_WFH, data);
  }
};

export default ScheduleUnitService;
