<template>
  <div>
    <v-row class="mx-1">
      <v-col cols="12">
        <v-card id="card-custom" class="px-2 py-4 rounded-lg">
          <v-card-actions>
            <p class="headline-color px-2">Penjadwalan Period</p>
          </v-card-actions>
          <v-divider />
          <v-row class="mx-2" id="card-custom">
            <v-col cols="12" xl="3" lg="3" md="3" sm="3" xs="12">
              <v-select
                placeholder="Unit Utama"
                :items="unitList"
                v-model="filter.unit_utama_id"
                :loading="loadingUnit"
                outlined
                dense
                hide-details
                clearable
                item-text="singkatan"
                item-value="unit_utama_id"
                @change="changeUnitList(0)"
                @click:clear="changeUnitList(0)"
              ></v-select>
            </v-col>
            <v-col cols="12" xl="3" lg="3" md="3" sm="3" xs="12">
              <v-select
                placeholder="Unit Kerja Group"
                :items="unitList1"
                v-model="filter.uk_group_id"
                :loading="loadingUnit"
                outlined
                dense
                hide-details
                clearable
                item-text="nama_uk_group"
                item-value="uk_group_id"
                @change="changeUnitList(1)"
                @click:clear="changeUnitList(1)"
              ></v-select>
            </v-col>
            <v-col cols="12" xl="3" lg="3" md="3" sm="3" xs="12">
              <v-select
                placeholder="Unit Kerja"
                :items="unitList2"
                v-model="filter.unit_kerja_2_id"
                :loading="loadingUnit"
                outlined
                dense
                hide-details
                clearable
                item-text="singkatan"
                item-value="unit_kerja_2_id"
              ></v-select>
            </v-col>
            <v-col cols="12" xl="3" lg="3" md="3" sm="3" xs="12">
              <v-text-field
                v-model="filter.search"
                placeholder="Cari"
                outlined
                dense
                hide-details
                prepend-inner-icon="search"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row id="card-custom">
            <v-col cols="12" xl="8" lg="8" md="8" sm="12" xs="12">
              <v-btn
                text
                class="text-capitalize caption headline-color"
                color="primary"
                @click="
                  form.id = null;
                  visible = true;
                "
                >Tambah Jadwal Periode<v-icon class="ml-2" color="primary"
                  >add_circle</v-icon
                ></v-btn
              >
            </v-col>
            <v-col cols="12" xl="4" lg="4" md="4" sm="12" xs="12"> </v-col>
          </v-row>
          <v-divider />
          <v-data-table
            id="table-custom"
            :headers="headers"
            :items="items"
            :loading="loading"
            :options.sync="options"
            :server-items-length="pagination.totalItem"
            :footer-props="{
              'items-per-page-options': rowsPerPageItems
            }"
            loading-text="Loading... Please wait"
            item-key="no"
          >
            <template v-slot:[`item.name`]="{ item }">
              {{ item.name }}
            </template>
            <template v-slot:[`item.unit`]="{ item }">
              <div class="py-1">
                <p class="mb-0">
                  <b>Unit Utama: </b>{{ item.nama_unit_utama }}
                </p>
                <p class="mb-0">
                  <b>Unit Kerja Group: </b>{{ item.nama_uk_group }}
                </p>
                <p class="mb-0">
                  <b>Unit Kerja: </b>{{ item.nama_unit_kerja_2 }}
                </p>
              </div>
            </template>
            <template v-slot:[`item.start_date`]="{ item }">
              {{ item.start_date | date }}
            </template>
            <template v-slot:[`item.end_date`]="{ item }">
              {{ item.end_date | date }}
            </template>
            <template v-slot:[`item.actions`]="{ item }">
              <v-btn
                x-small
                color="#297BBF"
                class="mr-2 my-1 white--text"
                @click="detailUnitJadwal(item)"
                >Detail</v-btn
              >
              <v-btn
                x-small
                color="#FBB005"
                class="my-1 white--text"
                @click="handleDelete(item)"
                >Hapus</v-btn
              >
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>
    <v-dialog v-model="visible" persistent max-width="600">
      <v-card class="pa-5 rounded-lg" id="card-custom">
        <v-btn @click="visible = false" x-small icon class="float-right">
          <v-icon>close</v-icon>
        </v-btn>
        <v-card-text class="subtitle-2 px-0 font-weight-regular">
          Buat Jadwal Periode
        </v-card-text>
        <v-divider class="mb-4" />
        <v-form
          id="form"
          ref="form"
          v-model="valid"
          lazy-validation
          @submit.prevent="save()"
        >
          <v-row>
            <v-col cols="5" class="py-0">
              <v-subheader class="caption headline-color font-weight-regular"
                >Nama Jadwal</v-subheader
              >
            </v-col>
            <v-col cols="7" class="py-0">
              <v-text-field
                v-model="form.name"
                :rules="[v => !!v || 'Nama harus diisi']"
                :disabled="form.id != null"
                outlined
                dense
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="5" class="py-0">
              <v-subheader class="caption headline-color font-weight-regular"
                >Unit Jadwal</v-subheader
              >
            </v-col>
            <v-col cols="7" class="py-0">
              <v-autocomplete
                :items="scheduleUnits"
                outlined
                dense
                v-model="form.schedule_unit_id"
                :disabled="form.id != null"
                :loading="loadingScheduleUnit"
                :rules="[v => !!v || 'Unit jadwal harus diisi']"
                item-text="name"
                item-value="id"
              ></v-autocomplete>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="5" class="py-0">
              <v-subheader class="caption headline-color font-weight-regular"
                >Tanggal Mulai</v-subheader
              >
            </v-col>
            <v-col cols="7" class="py-0">
              <v-text-field
                v-model="form.start_date"
                :rules="[v => !!v || 'Tanggal mulai harus diisi']"
                :disabled="form.id != null"
                outlined
                dense
                type="date"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="5" class="py-0">
              <v-subheader class="caption headline-color font-weight-regular"
                >Tanggal Akhir</v-subheader
              >
            </v-col>
            <v-col cols="7" class="py-0">
              <v-text-field
                v-model="form.end_date"
                :rules="[v => !!v || 'Tanggal akhir harus diisi']"
                :disabled="form.id != null"
                outlined
                dense
                type="date"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="5" class="py-0">
              <v-subheader class="caption headline-color font-weight-regular"
                >Unit Utama</v-subheader
              >
            </v-col>
            <v-col cols="7" class="py-0">
              <v-autocomplete
                v-model="form.unit_utama_id"
                :items="units"
                :loading="loadingUnit"
                outlined
                dense
                clearable
                item-text="singkatan"
                item-value="unit_utama_id"
                @change="changeUnit(0)"
                @click:clear="changeUnit(0)"
                :rules="[v => !!v || 'Unit utama harus diisi']"
                :disabled="form.id != null"
              ></v-autocomplete>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="5" class="py-0">
              <v-subheader class="caption headline-color font-weight-regular"
                >Unit Kerja Group</v-subheader
              >
            </v-col>
            <v-col cols="7" class="py-0">
              <v-autocomplete
                v-model="form.uk_group_id"
                :items="units1"
                :loading="loadingUnit"
                outlined
                dense
                clearable
                item-text="nama_uk_group"
                item-value="uk_group_id"
                @change="changeUnit(1)"
                @click:clear="changeUnit(1)"
                :disabled="form.id != null"
              ></v-autocomplete>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="5" class="py-0">
              <v-subheader class="caption headline-color font-weight-regular"
                >Unit Kerja</v-subheader
              >
            </v-col>
            <v-col cols="7" class="py-0">
              <v-autocomplete
                v-model="form.unit_kerja_2_id"
                :items="units2"
                :loading="loadingUnit"
                outlined
                dense
                clearable
                item-text="singkatan"
                item-value="unit_kerja_2_id"
                :disabled="form.id != null"
              ></v-autocomplete>
            </v-col>
          </v-row>
          <v-card-actions class="mt-3">
            <v-spacer />
            <v-btn
              small
              outlined
              color="#717171"
              class="px-12"
              @click="visible = false"
              >Batal</v-btn
            >
            <v-btn
              v-if="!form.id"
              small
              color="#FBB005"
              class="px-12 subtitle-2 text-capitalize"
              :loading="formLoading"
              type="submit"
              :disabled="!valid"
              form="form"
              >{{ form.id ? "Ubah" : "Buat" }}</v-btn
            >
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import SchedulePeriodService from "@/services/resources/schedule-period.service";
import DataUtamaService from "@/services/resources/data.utama.service";
import ScheduleUnitService from "@/services/resources/schedule-unit.service";

export default {
  data() {
    return {
      visible: false,
      valid: false,
      loading: true,
      loadingUnit: false,
      loadingScheduleUnit: false,
      formLoading: false,
      isArchive: false,
      type: "ACTIVE",
      filter: {
        search: null,
        unit_utama_id: null,
        uk_group_id: null,
        unit_kerja_2_id: null
      },
      unitList: [],
      unitList1: [],
      unitList2: [],
      units: [],
      units1: [],
      units2: [],
      scheduleUnits: [],
      form: {
        id: null,
        name: null,
        unit_utama_id: null,
        uk_group_id: null,
        unit_kerja_2_id: null,
        start_date: null,
        end_date: null,
        schedule_unit_id: null
      },
      // DataTable
      pagination: {
        page: 1,
        itemsPerPage: 10,
        totalItem: 0,
        pageCount: 0
      },
      selected: [],
      selection: true,
      options: {
        sortBy: ["name"],
        sortDesc: [false]
      },
      rowsPerPageItems: [10, 20, 30, 40],
      headers: [
        { text: "No", value: "no" },
        { text: "NAMA", value: "name" },
        { text: "UNIT KERJA", value: "unit" },
        { text: "UNIT JADWAL", value: "nama_schedule_unit" },
        { text: "TANGGAL AWAL", value: "start_date" },
        { text: "TANGGAL AKHIR", value: "end_date" },
        { text: "", value: "actions" }
      ],
      items: []
    };
  },
  computed: {
    paginationProperty() {
      return [
        this.filter.search,
        this.filter.unit_utama_id,
        this.filter.uk_group_id,
        this.filter.unit_kerja_2_id
      ].join();
    }
  },
  watch: {
    isArchive(val) {
      if (val) {
        this.type = "INACTIVE";
      } else {
        this.type = "ACTIVE";
      }
    },
    options: {
      handler() {
        this.fetchListDebounce(this.getListSchedule);
      },
      deep: true
    },
    visible(val) {
      if (!val) {
        this.$refs.form.reset();
      }
    },
    paginationProperty: function() {
      this.options.page = 1;
      this.fetchListDebounce(this.getListSchedule);
    }
  },
  methods: {
    changeUnit(type = 0) {
      if (type == 0) {
        this.units1 = this.units.find(
          d => d.unit_utama_id == this.form.unit_utama_id
        )?.unit_kerja_group;
        this.form.uk_group_id = null;
        this.form.unit_kerja_2_id = null;
      } else if (type == 1) {
        this.units2 = this.units1.find(
          d => d.uk_group_id == this.form.uk_group_id
        )?.unit_kerja2;
        this.form.unit_kerja_2_id = null;
      }
    },
    changeUnitList(type = 0) {
      if (type == 0) {
        this.unitList1 = this.unitList.find(
          d => d.unit_utama_id == this.filter.unit_utama_id
        )?.unit_kerja_group;
        this.filter.uk_group_id = null;
        this.filter.unit_kerja_2_id = null;
      } else if (type == 1) {
        this.unitList2 = this.unitList1.find(
          d => d.uk_group_id == this.filter.uk_group_id
        )?.unit_kerja2;
        this.filter.unit_kerja_2_id = null;
      }
    },
    populateUnit() {
      this.units1 = this.units.find(
        d => d.unit_utama_id == this.form.unit_utama_id
      )?.unit_kerja_group;
      this.units2 = this.units1.find(
        d => d.uk_group_id == this.form.uk_group_id
      )?.unit_kerja2;
    },
    handleDelete(item) {
      this.$confirm({
        title: "Hapus Jadwal Periode",
        message: `Apakah Anda yakin akan menghapus jadwal periode ini?`,
        button: {
          no: "Batal",
          yes: "Hapus"
        },
        callback: confirm => {
          if (confirm) {
            this.deleteSchedule(item.id);
          }
        }
      });
    },
    detailUnitJadwal(item) {
      this.visible = true;
      this.getDetailSchedule(item.id);
    },
    save() {
      if (this.$refs.form.validate()) {
        var a = this.$moment(this.form.start_date);
        var b = this.$moment(this.form.end_date);
        let dayDiff = b.diff(a, "days");
        if (dayDiff < 0) {
          this.$store.commit("snackbar/setSnack", {
            show: true,
            message: "Masukan tanggal mulai lebih dahulu dari tanggal akhir",
            color: "error"
          });
          return;
        }
        if (this.form.id) {
          let payload = {
            id: this.form.id,
            name: this.form.name,
            unit_utama_id: this.form.unit_utama_id,
            uk_group_id: this.form.uk_group_id,
            unit_kerja_2_id: this.form.unit_kerja_2_id || null,
            start_date: this.form.start_date,
            end_date: this.form.end_date,
            schedule_unit_id: this.form.schedule_unit_id
          };
          this.saveSchedule(payload);
        } else {
          let payload = {
            id: 0,
            name: this.form.name,
            unit_utama_id: this.form.unit_utama_id,
            uk_group_id: this.form.uk_group_id,
            unit_kerja_2_id: this.form.unit_kerja_2_id || null,
            start_date: this.form.start_date,
            end_date: this.form.end_date,
            schedule_unit_id: this.form.schedule_unit_id
          };
          this.saveSchedule(payload);
        }
      }
    },
    // Service
    async getListScheduleUnit(search) {
      try {
        this.loadingScheduleUnit = true;
        await ScheduleUnitService.getList({
          filter: { search },
          orderBy: ["id", "asc"],
          pageCurrent: 1,
          dataPerPage: 100
        })
          .then(response => {
            const { status, data } = response.data;
            const { list } = data;
            if (status) {
              this.scheduleUnits = list;
            }
          })
          .catch(error => {
            throw new Error(error);
          })
          .finally(() => (this.loadingScheduleUnit = false));
      } catch (err) {
        console.error(err);
      }
    },
    async getListUnitKerjaGroup() {
      try {
        this.loadingUnit = true;
        await DataUtamaService.getUnitKerjaGroupLOV(0)
          .then(response => {
            const { status, data } = response.data;
            if (status) {
              this.units = data;
              this.unitList = data;
              this.populateUnit();
            }
          })
          .catch(error => {
            throw new Error(error);
          })
          .finally(() => (this.loadingUnit = false));
      } catch (err) {
        console.error(err);
      }
    },
    async getListSchedule() {
      try {
        const { page, itemsPerPage } = this.options;
        this.loading = true;
        await SchedulePeriodService.getList({
          filter: {
            search: this.filter.search,
            unit_utama_id: this.filter.unit_utama_id,
            uk_group_id: this.filter.uk_group_id,
            unit_kerja_2_id: this.filter.unit_kerja_2_id
          },
          orderBy: ["id", "asc"],
          pageCurrent: page,
          dataPerPage: itemsPerPage
        })
          .then(response => {
            const { status, data } = response.data;
            const { list, pageTotal, countTotal } = data;
            if (status) {
              let items = list;
              items.map((data, index) => {
                data.no = itemsPerPage * (page - 1) + (index + 1);
                return data;
              });

              this.items = items;
              this.pagination.totalItem = parseInt(countTotal);
              this.pagination.pageCount = parseInt(pageTotal);
            }
          })
          .catch(error => {
            throw new Error(error);
          })
          .finally(() => (this.loading = false));
      } catch (err) {
        console.error(err);
      }
    },
    async getDetailSchedule(id) {
      try {
        this.loading = true;
        await SchedulePeriodService.getDetail(id)
          .then(response => {
            const { status, data } = response.data;
            if (status) {
              data.start_date = this.$moment(data.start_date).format(
                "YYYY-MM-DD"
              );
              data.end_date = this.$moment(data.end_date).format("YYYY-MM-DD");
              this.form = this.$_.merge(this.form, data);
              this.populateUnit();
            }
          })
          .catch(error => {
            throw new Error(error);
          })
          .finally(() => (this.loading = false));
      } catch (err) {
        console.error(err);
      }
    },
    async saveSchedule(data) {
      try {
        this.formLoading = true;
        await SchedulePeriodService.save(data)
          .then(response => {
            const { status, message } = response.data;
            if (status) {
              this.getListSchedule();
              this.visible = false;
              this.$store.commit("snackbar/setSnack", {
                show: true,
                message: message,
                color: "success"
              });
            } else {
              this.$store.commit("snackbar/setSnack", {
                show: true,
                message: message,
                color: "error"
              });
            }
          })
          .catch(error => {
            throw new Error(error);
          })
          .finally(() => (this.formLoading = false));
      } catch (err) {
        console.error(err);
      }
    },
    async deleteSchedule(id, type = "delete") {
      try {
        this.formLoading = true;
        await SchedulePeriodService[type](id)
          .then(response => {
            const { status, message } = response.data;
            if (status) {
              this.selected = [];
              this.getListSchedule();
              this.$store.commit("snackbar/setSnack", {
                show: true,
                message: message,
                color: "success"
              });
            } else {
              this.$store.commit("snackbar/setSnack", {
                show: true,
                message: message,
                color: "error"
              });
            }
          })
          .catch(error => {
            throw new Error(error);
          })
          .finally(() => (this.formLoading = false));
      } catch (err) {
        console.error(err);
      }
    }
  },
  mounted() {
    this.getListUnitKerjaGroup();
    this.getListScheduleUnit();
    this.getListSchedule();
  }
};
</script>
<style lang="scss">
#table-custom.v-data-table {
  color: #717171 !important;
  .v-data-table__wrapper > table > thead > tr > th {
    font-size: 0.775rem;
  }
  .v-data-table__wrapper > table > tbody > tr > td {
    font-size: 0.775rem;
  }
}
#card-custom .v-input {
  font-size: 0.675rem;
}
#btn-type .v-btn--contained {
  background-color: #ffffff !important;
  .v-btn__content {
    color: #717171 !important;
  }
}
#btn-type .v-btn--active {
  background-color: #1792e6 !important;
  .v-btn__content {
    color: #ffffff !important;
  }
}
#custom-pagination {
  .v-pagination__navigation {
    min-width: 24px;
    width: 24px;
    height: 24px;
    .v-icon {
      font-size: 14px;
    }
  }
  .v-pagination__item {
    font-size: 0.6rem;
    min-width: 24px;
    width: 24px;
    height: 24px;
  }
}
</style>
