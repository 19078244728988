import BaseInstance from "../base.instance";
import {
  PRESENSI_SCHEDULE_PERIOD_LIST,
  PRESENSI_SCHEDULE_PERIOD_DETAIL,
  PRESENSI_SCHEDULE_PERIOD_SAVE,
  PRESENSI_SCHEDULE_PERIOD_DELETE
} from "../constants";

const SchedulePeriodService = {
  getList(data) {
    return BaseInstance.post(PRESENSI_SCHEDULE_PERIOD_LIST, data);
  },
  getDetail(id) {
    return BaseInstance.fetch(PRESENSI_SCHEDULE_PERIOD_DETAIL, id);
  },
  save(data) {
    return BaseInstance.post(PRESENSI_SCHEDULE_PERIOD_SAVE, data);
  },
  delete(id) {
    return BaseInstance.deleteSlug(PRESENSI_SCHEDULE_PERIOD_DELETE, id);
  }
};

export default SchedulePeriodService;
